<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="d-flex align-self-center justify-center align-center">
          <img
            width="40%"
            src="../../assets/conteudos_tematicos_religiosidade_popular_titulo.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row>
        <nav
          v-if="this.$route.meta.subpage !== true"
          class="container d-flex justify-center align-center pt-3 col-12 col-md-8"
        >
          <v-col
            class="about__page_nav-link d-flex justify-center flex-column align-center"
          >
            <router-link :to="{ name: 'conteudos_tematicos_religiosidade' }">
              Folia de Reis
            </router-link>
          </v-col>
          <v-col class="about__page_nav-link">
            <router-link
              :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
            >
              Calendário Litúrgico
            </router-link>
          </v-col>
        </nav>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="3" class="d-flex justify-end align-center">
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_14.png"
            alt=""
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <p style="text-align: center">
            Clique no link abaixo para baixar o Calendário Litúrgico
          </p>

          <p style="text-align: center;">
            <a
              target="_blank"
              style="text-decoration: none"
              href="https://drive.google.com/uc?id=1eRzYBl0XrzdMyNiQY0Br4yknj2Mbz18t&authuser=0&export=download"
              class="pink--text"
              >Calendário Litúrgico (PDF)</a
            >
          </p>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Religiosidade Popular /",
          href: "conteudos_tematicos_religiosidade",
        },
        {
          text: "Calendário Litúrgico",
          href: "conteudos_tematicos_religiosidade",
        },
      ],
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

a {
  color: #426e51;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #426e51;
}
</style>
